import App from '..';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import customerinfo from "../../data/customerinfo";
import Public from '../Public';
import Dashboard from '../../pages/_Dashboard';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <App /> }>
          <Route path="/" element={ <Dashboard /> } />
          {Object.keys(customerinfo).map((key) => {
            const Page = customerinfo[key].page;
            return <Route key={key} path={`/${key}`} element={ <Page /> } />
          })}
        </Route>

        <Route path="/public-view" element={ <Public /> }>
        {Object.keys(customerinfo).map((key) => {
            const Page = customerinfo[key].page;
            return <Route key={key} path={customerinfo[key].guid} element={ <Page publicView /> } />
          })}
          </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
