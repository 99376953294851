import { useState, useRef, useEffect } from "react";
import "./FSG.scss";
import customerinfo from "../../data/customerinfo";
import branches from "../../data/branches.json";
import { heatmapLayer } from "../../helpers/heatmapLayer";
import Map, {
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
  MapRef,
  Marker,
  Popup,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Header } from "../../components/Header";
import { ActionButtons } from "../../components/ActionButtons";
import { vendorBranchData } from "../../helpers/branchVendor";
import { AddressAutofill } from "@mapbox/search-js-react";
import { Button, TextInput } from "@fsg/gui-bits";
import Pin from "../../assets/pin";
import canadaWos from "../../data/canadaWos.json";
import * as turf from "@turf/turf";
import { Units } from "@turf/turf";

const canadaWosData = canadaWos.filter(
  (item: any) => item["Latitude"] && item["Longitude"]
);

const distanceBetween = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const from = turf.point([lon1, lat1]);
  const to = turf.point([lon2, lat2]);
  return turf.distance(from, to, { units: "miles" });
};

const circles: any[] = [];
branches.forEach((branch) => {
  if (
    circles.length &&
    circles.some(
      (c) => distanceBetween(c[1], c[0], branch.Latitude, branch.Longitude) < 50
    )
  ) {
    return;
  }
  circles.push([branch.Longitude, branch.Latitude]);
});
console.log(circles);

const mapData = vendorBranchData
  .map((d) => ({ Latitude: d.Latitude, Longitude: d.Longitude }))
  .concat(canadaWosData);

function FSGPage({ publicView = false }) {
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [customAddress, setCustomAddress] = useState<any>(null);
  const [showBranches, setShowBranches] = useState<boolean>(true);

  const map = useRef<MapRef>(null);

  document.title = `FSG ${publicView ? "(Public)" : ""} -  ${
    customerinfo.fsg.name
  } Coverage Map`;

  const setMap = (data: any) => {
    setCustomAddress(data.features[0]);
    map.current?.flyTo({
      center: data.features[0].geometry.coordinates,
      duration: 1000,
      zoom: 10,
    });
  };

  useEffect(() => {
    if (customAddress === null) {
      map.current?.flyTo({
        center: [-95.7, 37.09],
        zoom: 3,
        duration: 1000,
      });
    }
  }, [customAddress]);

  return (
    <div>
      <Header customer={customerinfo.fsg.name} />
      <div className="dashboard-content">
        <div>
          <ActionButtons
            customerGuid={customerinfo.fsg.guid}
            publicView={publicView}
          />

          <span style={{ marginLeft: "1rem" }}>
            <AddressAutofill
              accessToken={process.env.REACT_APP_MAPBOX_TOKEN!}
              onRetrieve={setMap}
            >
              <TextInput
                leftLabel="Search Map"
                placeholder="Enter an address"
                autoComplete="address-line-1"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </AddressAutofill>
          </span>
          {customAddress && (
            <span style={{ marginLeft: "1rem" }}>
              {customAddress.properties.place_name}
              <Button
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  setCustomAddress(null);
                  setSearch("");
                }}
              >
                Clear
              </Button>
            </span>
          )}
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={showBranches}
              onChange={() => setShowBranches(!showBranches)}
            />
            Show Branches
          </label>
        </div>
        <Map
          ref={map}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          initialViewState={{
            longitude: -95.7,
            latitude: 37.09,
            zoom: 3,
          }}
          style={{ width: "100%", height: "78vh", marginTop: "1rem" }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          // onClick={() => { setSelectedLocation(null) }}
        >
          <GeolocateControl position="top-left" />
          <FullscreenControl position="top-left" />
          <NavigationControl position="top-left" />
          <ScaleControl />

          <Source
            type="geojson"
            data={{
              type: "FeatureCollection",
              features: mapData.map((item) => ({
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Point",
                  coordinates: [item["Longitude"], item["Latitude"]],
                },
              })),
            }}
          >
            <Layer {...heatmapLayer}></Layer>
          </Source>
          {showBranches && (
            <>
              {branches.map((item: any) => (
                <Marker
                  key={item["Location ID"]}
                  longitude={item["Longitude"]}
                  latitude={item["Latitude"]}
                  onClick={(e) => {
                    e.originalEvent.preventDefault();
                    e.originalEvent.stopPropagation();
                    if (selectedLocation) {
                      setSelectedLocation(null);
                    } else {
                      setSelectedLocation(item);
                    }
                  }}
                  anchor="bottom"
                >
                  <Pin fill="#0060a9" />
                </Marker>
              ))}

              <Source
                id="fsg"
                type="geojson"
                data={{
                  type: "FeatureCollection",
                  features: circles.map((item) => {
                    const center = [item[0], item[1]];
                    const options = {
                      units: "miles" as Units,
                      properties: { foo: "bar" },
                    };
                    const circle = turf.circle(center, 200, options);
                    return circle;
                  }),
                }}
              >
                <Layer
                  type="fill"
                  paint={{
                    "fill-color": "green",
                    "fill-opacity": 0.4,
                  }}
                />
              </Source>

              {selectedLocation && (
                <Popup
                  anchor="top"
                  longitude={selectedLocation["Longitude"]}
                  latitude={selectedLocation["Latitude"]}
                  onClose={() => {
                    setSelectedLocation(null);
                  }}
                  style={{ width: "400px", maxWidth: "400px" }}
                >
                  <div>
                    <div className="selected-property">
                      <h2>
                        {selectedLocation["Name"]}
                        {" - "}
                        {selectedLocation["Division #"]}
                      </h2>
                      <div>
                        {selectedLocation["Address"]}
                        <br />
                        {selectedLocation["City"]}, {selectedLocation["State"]}{" "}
                        {selectedLocation["Zip Code"]}
                      </div>
                    </div>
                  </div>
                </Popup>
              )}
            </>
          )}

          {customAddress && (
            <Marker
              key={customAddress.properties.id}
              longitude={customAddress.geometry.coordinates[0]}
              latitude={customAddress.geometry.coordinates[1]}
            >
              <Pin size={40} fill="#a3c559" />
            </Marker>
          )}
        </Map>
      </div>
    </div>
  );
}

export default FSGPage;
