import { useState, useRef, useEffect } from 'react';
import './SalesHive.scss';
import customerinfo from "../../data/customerinfo";
import * as turf from '@turf/turf';
import { Units } from '@turf/turf';
import Map, { /* Marker, Popup, */
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
  MapRef,
  Marker,
  Popup,
} from "react-map-gl";
//import Pin from "../../assets/pin";
import "mapbox-gl/dist/mapbox-gl.css";
import { Header } from '../../components/Header';
import { ActionButtons } from '../../components/ActionButtons';
import { branchData, vendorData } from '../../helpers/branchVendor';
import { AddressAutofill } from '@mapbox/search-js-react';
import { Button, TextInput } from '@fsg/gui-bits';
import Pin from '../../assets/pin';

const activeBranches = [10,11,12,14,16,17,18,20,28,30,40,50,77,80];

const radiusLookup : { [key: string]: number} = {
  "TX": 150,
  "IN": 100,
}

const popupInfo : { [key: number] : any } = {
  10: {
    "Region": "Central",
    "City": "San Antonio",
    "Vice President": "Alan Giroux",
    "email" : "alan.giroux@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits","Electrical Construction to 30M","Telecom"]
  },
  11: {
    "Region": "Midwest",
    "City": "Kansas City",
    "Vice President": "Steve Frank",
    "email" : "steve.frank@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits","Electrical Construction to 5M"]
  },
  12: {
    "Region": "Western",
    "City": "Placentia",
    "Vice President": "Chad Owens",
    "email": "chad.owens@fsgi.com",
    "Capabilities": ["Electric Service and Lighting Retrofits"]
  },
  14: {
    "Region": "Northeast",
    "City": "New Jersey",
    "Vice President": "Stephen Malave",
    "email": "stephen.malave@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits","Electrical Construction to 2M"]
  },
  16: {
    "Region": "Western",
    "City": "Albuqurque",
    "Vice President": "Billy Ramirez",
    "email": "billy.ramirez@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits","Small Jobs to 250K"]
  },
  17: {
    "Region": "Western",
    "City": "Phoenix",
    "Vice President": "Michael Baucom",
    "email": "michael.baucom@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits","Small Jobs to 1MM"]
  },
  18: {
    "Region": "Western",
    "City": "El Paso",
    "Vice President": "Jerrod Kew",
    "email": "jerrod.kew@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits","Small Jobs to 250K"]
  },
  20: {
    "Region": "Central",
    "City": "Austin",
    "Vice President": "Sam Smith",
    "email": "sam.smith@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits","Electrical Construction to 30M","Telecom"]
  },
  28: {
    "Region": "Southeast",
    "City": "Tampa",
    "Vice President": "Victor Williams",
    "email": "victor.williams@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits"]
  },
  30: {
    "Region": "Central",
    "City": "Dallas",
    "Vice President": "Jeremy Ripley",
    "email": "jeremy.ripley@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits","Electrical Construction to 30M","Telecom"]
  },
  40: {
    "Region": "Central",
    "City": "Houston",
    "Vice President": "Brian Dwyer",
    "email": "brian.dwyer@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits","Electrical Construction to 30M","Telecom"]
  },
  50: {
    "Region": "Central",
    "City": "Corpus Christi",
    "Vice President": "Jason Zipprian",
    "email": "jason.zipprian@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits","Electrical Construction to 20M","Telecom"]
  },
  77: {
    "Region": "Western",
    "City": "Las Vegas",
    "Vice President": "Troy Boscia",
    "email": "troy.boscia@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits","Small Jobs to 100K"]
  },
  80: {
    "Region": "Midwest",
    "City": "Indianaplois",
    "Vice President": "Hunter Kasten",
    "email": "hunter.kasten@fsgi.com",
    "Capabilities": ["Electric Service","Lighting Retrofits","Small Jobs to 1MM"]
  }
}


const eastCoastExtras = [
  [41.179, -73.189],
  [39.74, -75.54],
  [42.36, -71.05],
  [39.29, -76.61],
  [35.22, -80.84],
  [41.20, -77.19],
  [32.77, -79.93],
  [36.85, -75.98]
]




function SalesHivePage({ publicView = false}) {
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [customAddress, setCustomAddress] = useState<any>(null);

  const map = useRef<MapRef>(null);

  const circles = branchData.filter(x => activeBranches.includes(x["Branch"])).map(x => ({
    "Latitude" : x["Latitude"],
    "Longitude" : x["Longitude"],
    "State": x["State"],
  })).concat(eastCoastExtras.map(x => ({
    "Latitude" : x[0],
    "Longitude" : x[1],
    "State": "NJ"
  })));

  document.title = `FSG ${publicView ? "(Public)" : ""} -  ${customerinfo.saleshive.name} Coverage Map`;

  const setMap = (data: any) => {
    setCustomAddress(data.features[0]);
    map.current?.flyTo({ center : data.features[0].geometry.coordinates, duration: 1000, zoom: 10 });
  }

  useEffect(() => {
    if (customAddress === null){
      map.current?.flyTo({ 
        center: [-95.7, 37.09 ],
        zoom: 3,
        duration: 1000,
      })
    }
  }, [customAddress])

  return (
    <div>
      <Header customer={customerinfo.saleshive.name} />
      <div className="dashboard-content">
        
        <ActionButtons customerGuid={customerinfo.saleshive.guid} publicView={publicView} />

        <span style={{marginLeft: "1rem"}}>
        <AddressAutofill accessToken={process.env.REACT_APP_MAPBOX_TOKEN!} onRetrieve={setMap}>
          <TextInput leftLabel='Search Map' placeholder="Enter an address" autoComplete='address-line-1' value={search} onChange={(e) => setSearch(e.target.value)} />
        </AddressAutofill>
        </span>
        { customAddress && 
          <span style={{marginLeft: "1rem"}}>
            {customAddress.properties.place_name}
            <Button style={{marginLeft: "1rem"}} onClick={() => {
              setCustomAddress(null);
              setSearch("")
            }}>Clear</Button>
          </span>
        }

        <Map
          ref={map}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          initialViewState={{
            longitude: -95.7,
            latitude: 37.09,
            zoom: 3
          }}
          style={{ width: "100%", height: "80vh", marginTop: "1rem" }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          onClick={() => { setSelectedLocation(null) }}
        >
          <GeolocateControl position="top-left" />
          <FullscreenControl position="top-left" />
          <NavigationControl position="top-left" />
          <ScaleControl />

          {customAddress && 
            <Marker
              key={customAddress.properties.id}
              longitude={customAddress.geometry.coordinates[0]}
              latitude={customAddress.geometry.coordinates[1]}
            >
              <Pin size={40} fill="#a3c559" />
            </Marker>
          }


          <Source id="non-fsg" type="geojson" data={{
            "type": "FeatureCollection",
            "features" : vendorData.map((item) => {
              const radius = 50;
              const center = [item["Longitude"], item["Latitude"]];
              const options = { steps: 50, units: "miles" as Units };
              const circle = turf.circle(center, radius, options);
              return circle;
            })
          }}>
            <Layer
              type="fill"
              paint={{
                "fill-color": "rgba(150,150,255,0.5)",
                "fill-opacity": 0.2
              }}
            />
          </Source>

          <Source id="fsg" type="geojson" data={{
            "type": "FeatureCollection",
            "features" : circles.map((item) => {
              const radius = radiusLookup[item["State"]] || 50;
              const center = [item["Longitude"], item["Latitude"]];
              const options = { steps: radius, units: "miles" as Units };
              const circle = turf.circle(center, radius, options);
              return circle;
            })
          }}>
            <Layer
              type="fill"
              paint={{
                "fill-color": "rgb(51,94,238)",
                "fill-opacity": 0.4
              }}
            />
          </Source>
          {branchData.filter(x => activeBranches.includes(x["Branch"])).map((item : any) =>
            <Marker
              longitude={item["Longitude"]}
              latitude={item["Latitude"]}
              onClick={(e) => {
                e.originalEvent.preventDefault();
                e.originalEvent.stopPropagation();
                if (selectedLocation) {
                  setSelectedLocation(null);
                } else {
                  setSelectedLocation(item);
                }
              }}
              anchor="bottom"
            >
                <Pin fill="#0060a9" />
            </Marker>
          )}

          {eastCoastExtras.map((item : any) =>
            <Marker
              longitude={item[1]}
              latitude={item[0]}
              onClick={(e) => {
                e.originalEvent.preventDefault();
                e.originalEvent.stopPropagation();
                if (selectedLocation) {
                  setSelectedLocation(null);
                } else {
                  const nj = branchData.find(x => x.Branch === 14)
                  setSelectedLocation(nj);
                }
              }}
              anchor="bottom"
            >
              <Pin fill="#0060a9" />
            </Marker>
          )}



          {selectedLocation && (
            <Popup
              anchor='top'
              longitude={selectedLocation["Longitude"]}
              latitude={selectedLocation["Latitude"]}
              onClose={() => {
                setSelectedLocation(null);
              }}
              style={{ width: "400px", maxWidth: "400px"}}
            >
              <div>
                <div className="selected-property">
                  <h2>{selectedLocation["Name"]}</h2>
                  <div>
                    {selectedLocation["Address"]}<br />{selectedLocation["City"]}, {selectedLocation["State"]} {selectedLocation["Zip Code"]}
                  </div>
                </div> 
                <div className="selected-property">
                  <table>
                    <tbody>
                      <tr>
                        <th>Region</th>
                        <td>{popupInfo[selectedLocation["Branch"]].Region}</td>
                      </tr>
                      <tr>
                        <th>Branch</th>
                        <td>{popupInfo[selectedLocation["Branch"]].City} - {selectedLocation["Branch"]}</td>
                      </tr>
                      <tr>
                        <th>VP</th>
                        <td>
                          {popupInfo[selectedLocation["Branch"]]["Vice President"]}<br />
                          <a href={`mailto:${popupInfo[selectedLocation["Branch"]]["email"]}`} target="_blank" rel="noreferrer">{popupInfo[selectedLocation["Branch"]]["email"]}</a>
                          </td>
                      </tr>
                      <tr>
                        <th>Capabilities</th>
                        <td>{popupInfo[selectedLocation["Branch"]]["Capabilities"].join(", ")}</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>            
              </div>
            </Popup>
          )}


        </Map>
      </div>
    </div>
  );
}

export default SalesHivePage;

