import { useState, useCallback, useRef } from 'react';
import './Belk.scss';
import belk from '../../data/belk.json';
import customerinfo from "../../data/customerinfo";
//import vendorPricing from '../../data/vendor_pricing.json';
import Map, { Marker, Popup, 
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
  MapRef,
} from "react-map-gl";
import Pin from "../../assets/pin";
import "mapbox-gl/dist/mapbox-gl.css";
import { heatmapLayer } from '../../helpers/heatmapLayer';
import { sortVendorsByDistance, vendorBranchData } from '../../helpers/branchVendor';
import { escapeString } from '../../helpers/escapeString';
import { downloadCsv } from '../../helpers/downloadCsv';
import { Header } from '../../components/Header';
import { ActionButtons } from '../../components/ActionButtons';
import { CardButton, TextInput } from '@fsg/gui-bits';

const belkData = belk.filter((item) => item["Latitude"] && item["Longitude"]);

function BelkPage({ publicView = false}) {
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [customerData, ] = useState<any>(belkData);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const map = useRef<MapRef>(null);

  document.title = `FSG ${publicView ? "(Public)" : ""} -  ${customerinfo.belk.name} Coverage Map`;

  const exportData = useCallback(() => {
    let csvContent = "Property ID,Name,Address,City,State,Zip,V1,V1 Distance,V2,V2 Distance,V3,V3 Distance,V4,V4 Distance,V5,V5 Distance\n"
    customerData.forEach(function (row : any) {
      const vendors = sortVendorsByDistance(row["Latitude"], row["Longitude"]);
      const rowArray = [
        row["Property ID"],
        row["Property Name"],
        row["Address"],
        row["City"],
        row["State"],
        row["Zip"],
        vendors[0]["Name"],
        vendors[0]["distance"].toFixed(1),
        vendors[1]["Name"],
        vendors[1]["distance"].toFixed(1),
        vendors[2]["Name"],
        vendors[2]["distance"].toFixed(1),
        vendors[3]["Name"],
        vendors[3]["distance"].toFixed(1),
        vendors[4]["Name"],
        vendors[4]["distance"].toFixed(1),
      ]
      csvContent += rowArray.map(escapeString).join(",");
      csvContent += "\n";
    });

    downloadCsv(csvContent, "belk");
  }, [customerData]);

  return (
    <div>
      <Header customer={customerinfo.belk.name} />
      <div className="dashboard-content">
        
        <ActionButtons customerGuid={customerinfo.belk.guid} publicView={publicView} exportData={exportData} />

        <div className="map-container">
        <div className="site-list">
            <div className="site-search">
              <TextInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search for a site" />
            </div>
            <div className="site-list-list">
              {customerData.filter((x : any) => {
                if (searchTerm === "") {
                  return true;
                }
                return `Store ${x["Location ID"]}`.toLowerCase().includes(searchTerm.toLowerCase()) || 
                x["Str Name"].toLowerCase().includes(searchTerm.toLowerCase()) ||
                x["Street Address"].toLowerCase().includes(searchTerm.toLowerCase()) ||
                `${x["Zip"]}`.toLowerCase().includes(searchTerm.toLowerCase());
              }).map((item : any) => {
                return (
                  <CardButton key={item["Name"]} className="site-listing" onClick={() => {
                    map.current?.flyTo({
                      center: [item["Longitude"], item["Latitude"]],
                      zoom: 10,
                      duration: 1000
                    });
                    setSelectedLocation(item);
                  }}>
                    <h4>Location {item["Location ID"]}</h4>
                    <address>
                      <div>{item["Street Address"]}</div><div>{item["Str Name"]} {item["Zip"]}</div>
                    </address>
                  </CardButton>
                )
              })}
            </div>
          </div>
          <Map
            ref={map}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={{
              longitude: -95.7,
              latitude: 37.09,
              zoom: 3
            }}
            style={{ width: "100%", height: "80vh", marginTop: "1rem" }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            onClick={() => { setSelectedLocation(null) }}
            onDrag={() => { setSelectedLocation(null) }}
          >
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />

            <Source type="geojson" data={{
              "type": "FeatureCollection",
              "features" : vendorBranchData.map((item) => ({
                "type": "Feature",
                "properties": {
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [item["Longitude"], item["Latitude"]]
                }
              }))
            }}>
              <Layer {...heatmapLayer} ></Layer>
            </Source>

            {customerData.map((item : any) =>
              <Marker
                key={item["Location ID"]}
                longitude={item["Longitude"]}
                latitude={item["Latitude"]}
                onClick={(e) => {
                  e.originalEvent.preventDefault();
                  e.originalEvent.stopPropagation();
                  if (selectedLocation) {
                    setSelectedLocation(null);
                  } else {
                    setSelectedLocation(item);
                  }
                }}
                anchor="bottom"
              >
                <Pin fill="rgb(202,6,188)" />
              </Marker>
            )}
            {selectedLocation && (
              <Popup
                anchor='top'
                longitude={selectedLocation["Longitude"]}
                latitude={selectedLocation["Latitude"]}
                onClose={() => {
                  setSelectedLocation(null);
                }}
                style={{ width: "400px", maxWidth: "400px"}}
              >
                <div>
                  <div className="selected-property">
                    
                  <h4>Location {selectedLocation["Location ID"]}</h4>
                    <address>
                      <div>{selectedLocation["Street Address"]}</div><div>{selectedLocation["Str Name"]} {selectedLocation["Zip"]}</div>
                    </address>
                  </div>
                  {!publicView && 
                    <div>
                      {sortVendorsByDistance(selectedLocation["Latitude"], selectedLocation["Longitude"]).map((item, i) => 
                        <div key={`vendor-${i}`} className="vendor-listing">
                          <div className="heading">
                            <h3>{item["Name"]}</h3>
                            <div>{item.distance.toFixed(1)} miles</div>
                          </div>
                          <address>
                            <div>{item["Address"]}, {item["City"]}, {item["State"]} {item["Zip Code"]}</div>
                          </address>
                        </div>
                      )}
                    </div>
                  }
                </div>
              </Popup>
            )}
          </Map>
        </div>
      </div>
    </div>
  );
}

export default BelkPage;
