import { useState, useCallback, useRef } from 'react';
import './Michaels.scss';
import michaels from '../../data/michaels.json';
import customerinfo from "../../data/customerinfo";
//import vendorPricing from '../../data/vendor_pricing.json';
import Map, { Marker, Popup, 
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Layer,
  Source,
  MapRef,
} from "react-map-gl";
import Pin from "../../assets/pin";
import "mapbox-gl/dist/mapbox-gl.css";
import { sortVendorsByDistance, vendorData, branchData } from '../../helpers/branchVendor';
import { escapeString } from '../../helpers/escapeString';
import { downloadCsv } from '../../helpers/downloadCsv';
import { Header } from '../../components/Header';
import { ActionButtons } from '../../components/ActionButtons';
import { CardButton, TextInput } from '@fsg/gui-bits';
import * as turf from '@turf/turf';
import { Units } from '@turf/turf';

//const activeBranches = [10,11,12,14,16,17,18,20,28,30,40,50,77,80];

const radiusLookup : { [key: string]: number} = {
  "TX": 150,
  "IN": 100,
}

const michaelsData = michaels.filter((item : any) => item["Latitude"] && item["Longitude"]);


function MichaelsPage({ publicView = false}) {
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);
  const [customerData, ] = useState<any>(michaelsData);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const map = useRef<MapRef>(null);

  const circles = branchData.map(x => ({
    "Latitude" : x["Latitude"],
    "Longitude" : x["Longitude"],
    "State": x["State"]
  }));
  // })).concat(eastCoastExtras.map(x => ({
  //   "Latitude" : x[0],
  //   "Longitude" : x[1],
  //   "State": "NJ"
  // })));

  document.title = `FSG ${publicView ? "(Public)" : ""} -  ${customerinfo.michaels.name} Coverage Map`;

  const exportData = useCallback(() => {

    let csvContent = "City,State,Zip,V1,V1 Distance,V2,V2 Distance,V3,V3 Distance,V4,V4 Distance,V5,V5 Distance\n"
    customerData.forEach(function (row : any) {
      const vendors = sortVendorsByDistance(row["Latitude"], row["Longitude"]);
      const rowArray = [
        row["City"],
        row["State"],
        row["Zip"],
        vendors[0]["Name"],
        vendors[0]["distance"].toFixed(1),
        vendors[1]["Name"],
        vendors[1]["distance"].toFixed(1),
        vendors[2]["Name"],
        vendors[2]["distance"].toFixed(1),
        vendors[3]["Name"],
        vendors[3]["distance"].toFixed(1),
        vendors[4]["Name"],
        vendors[4]["distance"].toFixed(1),
      ]
      csvContent += rowArray.map(escapeString).join(",");
      csvContent += "\n";
    });

    downloadCsv(csvContent, "michaels");
  }, [customerData]);

  return (
    <div>
      <Header customer={customerinfo.michaels.name} />
      <div className="dashboard-content">

        <ActionButtons customerGuid={customerinfo.michaels.guid} publicView={publicView} exportData={exportData} />

        <div className="map-container">
        <div className="site-list">
            <div className="site-search">
              <TextInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search for a site" />
            </div>
            <div className="site-list-list">
              {customerData.filter((x : any) => {
                if (searchTerm === "") {
                  return true;
                }
                return x["STORE"].toLowerCase().includes(searchTerm.toLowerCase()) || 
                  x["ADDRESS"].toLowerCase().includes(searchTerm.toLowerCase()) ||
                  x["CITY"].toLowerCase().includes(searchTerm.toLowerCase()) ||
                  x["OperationsMarketName"].toLowerCase().includes(searchTerm.toLowerCase());
              }).map((item : any) => {
                return (
                  <CardButton key={item["Name"]} className="site-listing" onClick={() => {
                    map.current?.flyTo({
                      center: [item["Longitude"], item["Latitude"]],
                      zoom: 10,
                      duration: 1000
                    })
                  }}>
                    <h4>{item["STORE"]}</h4>
                    <address>
                      <div>{item["ADDRESS"]}</div><div>{item["CITY"]}, {item["ST"]} {item["ZIP CODE"]}</div>
                    </address>
                  </CardButton>
                )
              })}
            </div>
          </div>
          <Map
            ref={map}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={{
              longitude: -95.7,
              latitude: 37.09,
              zoom: 3
            }}
            style={{ width: "100%", height: "80vh", marginTop: "1rem" }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            onClick={() => { setSelectedLocation(null) }}
            onDrag={() => { setSelectedLocation(null) }}
          >
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />


            <Source id="non-fsg" type="geojson" data={{
            "type": "FeatureCollection",
            "features" : vendorData.map((item) => {
              const radius = 50;
              const center = [item["Longitude"], item["Latitude"]];
              const options = { steps: 50, units: "miles" as Units , properties: { foo: "bar" } };
              const circle = turf.circle(center, radius, options);
              return circle;
            })
          }}>
            <Layer
              type="fill"
              paint={{
                "fill-color": "rgba(150,150,255,0.5)",
                "fill-opacity": 0.2
              }}
            />
          </Source>

          <Source id="fsg" type="geojson" data={{
            "type": "FeatureCollection",
            "features" : circles.map((item) => {
              const radius = radiusLookup[item["State"]] || 50;
              const center = [item["Longitude"], item["Latitude"]];
              const options = { steps: 50, units: "miles" as Units , properties: { foo: "bar" } };
              const circle = turf.circle(center, radius, options);
              return circle;
            })
          }}>
            <Layer
              type="fill"
              paint={{
                "fill-color": "rgb(51,94,238)",
                "fill-opacity": 0.4
              }}
            />
          </Source>

            {customerData.map((item : any) =>
              <Marker
                key={item["External Property ID"]}
                longitude={item["Longitude"]}
                latitude={item["Latitude"]}
                onClick={(e) => {
                  e.originalEvent.preventDefault();
                  e.originalEvent.stopPropagation();
                  if (selectedLocation) {
                    setSelectedLocation(null);
                  } else {
                    setSelectedLocation(item);
                  }
                }}
                anchor="bottom"
              >
                <Pin fill="rgb(207, 31, 46)" />
              </Marker>
            )}
            {selectedLocation && (
              <Popup
                anchor='top'
                longitude={selectedLocation["Longitude"]}
                latitude={selectedLocation["Latitude"]}
                onClose={() => {
                  setSelectedLocation(null);
                }}
                style={{ width: "40vw", maxWidth: "500px"}}
              >
                <div>
                  <div className="selected-property">
                    <h2>{selectedLocation["STORE"]}</h2>
                    <div>
                      {selectedLocation["ADDRESS"]}<br />{selectedLocation["CITY"]}, {selectedLocation["ST"]} {selectedLocation["ZIP CODE"]}
                    </div>
                  </div>
                  <div className="selected-property">
                    <div>
                    <strong>Ranking</strong> {selectedLocation["Ranking"]}<br />
                    <strong>Shopping Center</strong> {selectedLocation["Shopping Center"]}<br />
                    <strong>Miles Away</strong> {selectedLocation["Miles Away"]}<br />
                    </div>
                    <div>
                    <strong>Market Name</strong> {selectedLocation["OperationsMarketName"]}<br />
                    <strong>Conveyor</strong> {selectedLocation["Conveyor?"]} {selectedLocation["Conveyor Service"]}<br />
                    </div>
                  </div>
                  {!publicView && 
                    <div>
                      {sortVendorsByDistance(selectedLocation["Latitude"], selectedLocation["Longitude"]).map((item, i) => 
                        <div key={`vendor-${i}`} className="vendor-listing">
                          <div className="heading">
                            <h3>{item["Name"]}</h3>
                            <div>{item.distance.toFixed(1)} miles</div>
                          </div>
                          <address>
                            <div>{item["Address"]}, {item["City"]}, {item["State"]} {item["Zip Code"]}</div>
                          </address>
                        </div>
                      )}
                    </div>
                  }
                </div>
              </Popup>
            )}

            {branchData.map((item : any) =>
              <Marker
                longitude={item["Longitude"]}
                latitude={item["Latitude"]}
                onClick={(e) => {
                  e.originalEvent.preventDefault();
                  e.originalEvent.stopPropagation();
                  if (selectedLocation || selectedBranch) {
                    setSelectedLocation(null);
                    setSelectedBranch(null);
                  } else {
                    setSelectedBranch(item);
                  }
                }}
                anchor="bottom"
              >
                  <Pin fill="#0060a9" />
              </Marker>
            )}

            {selectedBranch && (
            <Popup
              anchor='top'
              longitude={selectedBranch["Longitude"]}
              latitude={selectedBranch["Latitude"]}
              onClose={() => {
                setSelectedBranch(null);
              }}
              style={{ width: "400px", maxWidth: "400px"}}
            >
              <div>
                <div className="selected-property">
                  <h2>{selectedBranch["Name"]}</h2>
                  <div>
                    {selectedBranch["Address"]}<br />{selectedBranch["City"]}, {selectedBranch["State"]} {selectedBranch["Zip Code"]}
                  </div>
                </div> 
                {/* <div className="selected-property">
                  <table>
                    <tbody>
                      <tr>
                        <th>Region</th>
                        <td>{popupInfo[selectedLocation["Branch"]].Region}</td>
                      </tr>
                      <tr>
                        <th>Branch</th>
                        <td>{popupInfo[selectedLocation["Branch"]].City} - {selectedLocation["Branch"]}</td>
                      </tr>
                      <tr>
                        <th>VP</th>
                        <td>
                          {popupInfo[selectedLocation["Branch"]]["Vice President"]}<br />
                          <a href={`mailto:${popupInfo[selectedLocation["Branch"]]["email"]}`} target="_blank" rel="noreferrer">{popupInfo[selectedLocation["Branch"]]["email"]}</a>
                          </td>
                      </tr>
                      <tr>
                        <th>Capabilities</th>
                        <td>{popupInfo[selectedLocation["Branch"]]["Capabilities"].join(", ")}</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>             */}
              </div>
            </Popup>
          )}

          </Map>
        </div>
      </div>
    </div>
  );
}

export default MichaelsPage;
