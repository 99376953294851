import { HeatmapLayer } from 'react-map-gl';


export const MAX_ZOOM_LEVEL = 20;
const MILES = 25;
const PIXEL_MILE_RATIO = 97.2618456;
const RADII = (function() {
  const result = [];
  for (let i = 1; i <= MAX_ZOOM_LEVEL; i++) {
    result.push(i);
    result.push(Math.pow(2, i) * ((MILES + (MAX_ZOOM_LEVEL * 10 - i * 10) )  / PIXEL_MILE_RATIO));
  }
  return result;
})();

export const heatmapLayer: HeatmapLayer = {
  id: 'heatmap',
  maxzoom: MAX_ZOOM_LEVEL + 1,
  type: 'heatmap',
  paint: {
    'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 4],
    'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 0, MAX_ZOOM_LEVEL, 10],
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(51,94,238,0)',
      0.2,
      'rgba(51,94,238,0.2)',
      0.4,
      'rgba(51,94,238,0.4)',
      0.6,
      'rgba(51,94,238,0.6)',
      0.8,
      'rgba(51,94,238,0.8)',
      0.9,
      'rgba(51,94,238,0.9)'
    ],
    'heatmap-radius': ['interpolate', ['linear'], ['zoom'], ...RADII],
    'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 0, 0.75, MAX_ZOOM_LEVEL, 0.95]
  }
};
