import vendors from '../data/vendors.json';
import branches from '../data/branches.json';
import { distanceByLatLong } from './distanceByLatLong';

export const vendorData = vendors.filter((item) => item["Latitude"] && item["Longitude"]).map(x => {
  return {
    "Name": x["Name"],
    "Address": x["Address"],
    "City": x["City"],
    "State": x["St"],
    "Zip Code": x["Zip Code"],
    "Latitude": x["Latitude"],
    "Longitude": x["Longitude"],
    isFSG: false,
    "Branch" : 0
  };
});
export const branchData = branches.filter((item) => item["Latitude"] && item["Longitude"]).map(x => {
  return {
    "Name": `FSG ${x["City"]} (${x["Name"]})`,
    "Address": x["Address"],
    "City": x["City"],
    "State": x["State"],
    "Zip Code": x["Zip"],
    "Latitude": x["Latitude"],
    "Longitude": x["Longitude"],
    isFSG: true,
    "Branch": x["Division #"]
  };
});
export const vendorBranchData = vendorData.concat(branchData);
export function sortVendorsByDistance(lat: number, long: number) {
  const sortedVendors = vendorBranchData.map((item) => {
    const distance = distanceByLatLong(lat, long, item["Latitude"], item["Longitude"]);
    return {
      ...item,
      distance
    };
  }).sort((a, b) => a.distance - b.distance);

  // return first 5 vendors
  return sortedVendors.slice(0, 5);
}