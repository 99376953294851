import React from "react"
import { Header } from "../../components/Header"
import customerInfo from "../../data/customerinfo";
import { CardButton } from "@fsg/gui-bits";
import { useNavigate } from "react-router-dom";
import './dashboard.scss';

export default function Dashboard() {
  const navigate = useNavigate();

  return ( <div>
    <Header customer={""} />
    <div className="dashboard-content">
      <div className="dashboard-cards">
      {Object.keys(customerInfo).map((key) => {
        return <CardButton key={key} onClick={() => navigate(`/${key}`)} className="card-button">
          <h2>{customerInfo[key].name} Map</h2>
        </CardButton>
      })}
      </div>
    </div>
    </div>)
}