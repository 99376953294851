import { useState, useRef, useEffect } from 'react';
import './Sites.scss';
import customerinfo from "../../data/customerinfo";
import Map, { 
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
  MapRef,
  Marker,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Header } from '../../components/Header';
import { ActionButtons } from '../../components/ActionButtons';
import sites from '../../data/sites.json';
import { AddressAutofill } from '@mapbox/search-js-react';
import { Button, TextInput } from '@fsg/gui-bits';
import Pin from '../../assets/pin';
import { heatmapLayer, MAX_ZOOM_LEVEL } from '../../helpers/heatmapLayer';
import canadaWos from '../../data/canadaWos.json';


const canadaWosData = canadaWos.filter((item : any) => item["Latitude"] && item["Longitude"]);
const sitesData = sites.concat(canadaWosData.map((item : any) => [item["Latitude"], item["Longitude"]]));

function SitesPage({ publicView = false}) {
  // const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [customAddress, setCustomAddress] = useState<any>(null);
  const [zoom, setZoom] = useState<number>(3);
  

  const map = useRef<MapRef>(null);

  document.title = `FSG ${publicView ? "(Public)" : ""} - ${customerinfo.sites.name} Coverage Map`;

  const setMap = (data: any) => {
    setCustomAddress(data.features[0]);
    map.current?.flyTo({ center : data.features[0].geometry.coordinates, duration: 1000, zoom: 10 });
  }

  useEffect(() => {
    if (customAddress === null){
      map.current?.flyTo({ 
        center: [-95.7, 37.09 ],
        zoom: 3,
        duration: 1000,
      })
    }
  }, [customAddress])

  return (
    <div>
      <Header customer={customerinfo.sites.name} />
      <div className="dashboard-content">
        
        <ActionButtons customerGuid={customerinfo.sites.guid} publicView={publicView} />
        <span style={{marginLeft: "1rem"}}>
        <AddressAutofill accessToken={process.env.REACT_APP_MAPBOX_TOKEN!} onRetrieve={setMap}>
          <TextInput leftLabel='Search Map' placeholder="Enter an address" autoComplete='address-line-1' value={search} onChange={(e) => setSearch(e.target.value)} />
        </AddressAutofill>
        </span>
        { customAddress && 
          <span style={{marginLeft: "1rem"}}>
            {customAddress.properties.place_name}
            <Button style={{marginLeft: "1rem"}} onClick={() => {
              setCustomAddress(null);
              setSearch("")
            }}>Clear</Button>
          </span>
        }

        <Map
          ref={map}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          initialViewState={{
            longitude: -95.7,
            latitude: 37.09,
            zoom: zoom
          }}
          maxZoom={MAX_ZOOM_LEVEL}
          onZoom={(e) => setZoom(e.viewState.zoom)}
          style={{ width: "100%", height: "80vh", marginTop: "1rem" }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          // onClick={() => { setSelectedLocation(null) }}
        >
          <GeolocateControl position="top-left" />
          <FullscreenControl position="top-left" />
          <NavigationControl position="top-left" />
          <ScaleControl />

          {customAddress && 
            <Marker
              key={customAddress.properties.id}
              longitude={customAddress.geometry.coordinates[0]}
              latitude={customAddress.geometry.coordinates[1]}
            >
              <Pin size={40} fill="#a3c559" />
            </Marker>
          }
          <Source type="geojson" data={{
            "type": "FeatureCollection",
            "features" : sitesData.map((item) => ({
              "type": "Feature",
              "properties": {
              },
              "geometry": {
                "type": "Point",
                "coordinates": [item[1], item[0]]
              }
            }))
          }}>
            <Layer {...heatmapLayer} ></Layer>
          </Source>
        </Map>
      </div>
    </div>
  );
}

export default SitesPage;

