import { Button, ButtonGroup } from "@fsg/gui-bits"

type ActionButtonsProps = {
  publicView: boolean;
  customerGuid: string;
  exportData?: () => void;
}

export const ActionButtons = ({ publicView, customerGuid, exportData} : ActionButtonsProps) => {
  return !publicView ? 
    <ButtonGroup> 
      { exportData !== undefined && <Button buttonType="secondary" onClick={exportData}>Export Data</Button>}
      <Button buttonType="secondary" onClick={() => window.open(`/public-view/${customerGuid}`)}>Public (Customer) View</Button>
    </ButtonGroup>
    : null
}