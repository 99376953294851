import { useState, useRef } from 'react';
import './BestBuy.scss';
import customerinfo from "../../data/customerinfo";
import Map, { 
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
  MapRef,
  Marker,
  Popup,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Header } from '../../components/Header';
import { ActionButtons } from '../../components/ActionButtons';
import national from '../../data/sites.json';
import { CardButton, TextInput } from '@fsg/gui-bits';
import Pin from '../../assets/pin';
import bestbuy from '../../data/bestbuy.json';
import { heatmapLayer, MAX_ZOOM_LEVEL } from '../../helpers/heatmapLayer';

const nationalData = national;

const bestBuyData: any = bestbuy;

function BestBuyPage({ publicView = false}) {
  // const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [customerData, ] = useState<any>(bestBuyData);
  const [zoom, setZoom] = useState<number>(3);
  

  const map = useRef<MapRef>(null);

  document.title = `Sites - ${customerinfo.bestbuy.name} Coverage Map`;

  return (
    <div>
      <Header customer={customerinfo.bestbuy.name} />
      <div className="dashboard-content">
        
        <ActionButtons customerGuid={customerinfo.bestbuy.guid} publicView={publicView} />

<div className="map-container">
<div className="site-list">
            <div className="site-search">
              <TextInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search for a site" />
            </div>
            <div className="site-list-list">
              {customerData.filter((x : any) => {
                if (searchTerm === "") {
                  return true;
                }
                const search = searchTerm.toLowerCase();
                return `Store ${x["Site"]}`.toLowerCase().includes(search) || 
                x["Site Name"].toLowerCase().includes(search) ||
                x["Address"].toLowerCase().includes(search) ||
                x["City"].toLowerCase().includes(search) ||
                `${x["Zip"]}`.toLowerCase().includes(search);
              }).map((item : any) => {
                return (
                  <CardButton key={item["Name"]} className="site-listing" onClick={() => {
                    map.current?.flyTo({
                      center: [item["Longitude"], item["Latitude"]],
                      zoom: 10,
                      duration: 1000
                    });
                    setSelectedLocation(item);
                  }}>
                    <h4>Location {item["Site"].replace("_", "")}</h4>
                    <address>
                      <div>{item["Site Name"]}</div>
                      <div>{item["Address"]}</div><div>{item["City"]}, {item["State"]} {item["Zip"]}</div>
                    </address>
                  </CardButton>
                )
              })}
            </div>
          </div>
        <Map
          ref={map}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          initialViewState={{
            longitude: -95.7,
            latitude: 37.09,
            zoom: zoom
          }}
          maxZoom={MAX_ZOOM_LEVEL}
          onZoom={(e) => setZoom(e.viewState.zoom)}
          style={{ width: "100%", height: "80vh", marginTop: "1rem" }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          // onClick={() => { setSelectedLocation(null) }}
        >
          <GeolocateControl position="top-left" />
          <FullscreenControl position="top-left" />
          <NavigationControl position="top-left" />
          <ScaleControl />

          <Source type="geojson" data={{
            "type": "FeatureCollection",
            "features" : nationalData.map((item) => ({
              "type": "Feature",
              "properties": {
              },
              "geometry": {
                "type": "Point",
                "coordinates": [item[1], item[0]]
              }
            }))
          }}>
            <Layer {...heatmapLayer} ></Layer>
          </Source>
          {customerData.map((item : any) =>
              <Marker
                key={item["Location ID"]}
                longitude={item["Longitude"]}
                latitude={item["Latitude"]}
                onClick={(e) => {
                  e.originalEvent.preventDefault();
                  e.originalEvent.stopPropagation();
                  if (selectedLocation) {
                    setSelectedLocation(null);
                  } else {
                    setSelectedLocation(item);
                  }
                }}
                anchor="bottom"
              >
                <Pin fill="#FFED31" stroke="#0A4ABF" />
              </Marker>
            )}
            {selectedLocation && (
              <Popup
                anchor='top'
                longitude={selectedLocation["Longitude"]}
                latitude={selectedLocation["Latitude"]}
                onClose={() => {
                  setSelectedLocation(null);
                }}
                style={{ width: "400px", maxWidth: "400px"}}
              >
                <div>
                  <div className="selected-property">
                    
                    <h4>Location {selectedLocation["Site"].replace("_", "")}</h4>
                    <address>
                      <div>{selectedLocation["Site Name"]}</div>
                      <div>{selectedLocation["Address"]}</div><div>{selectedLocation["City"]}, {selectedLocation["State"]} {selectedLocation["Zip"]}</div>
                    </address>
                  </div>
                </div>
              </Popup>
            )}
        </Map>
        </div>
      </div>
    </div>
  );
}

export default BestBuyPage;

