import React from "react"
import { Outlet } from "react-router-dom"
import { ReactComponent as Logo } from "../assets/logo.svg"
import "./App.scss"

export default function Public () {
  return (
      <main className="public">        
        <Logo style={{ marginBottom : "1rem"}} />
        <Outlet />
      </main>
  )
}