import { HeaderText } from "@fsg/gui-bits";

export const Header = ({ customer }: { customer: string}) => {
  return (
    <div className="dashboard-header">
    <HeaderText className="page-header">
      <>FSG - {customer} Coverage Map</>
    </HeaderText>
  </div>
  );
};