import { useState, useCallback, useRef, useEffect } from "react";
import "./USPS.scss";
import usps from "../../data/uspsTrim.json";
import customerinfo from "../../data/customerinfo";
//import vendorPricing from '../../data/vendor_pricing.json';
import Map, {
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Layer,
  Source,
  MapRef,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { heatmapLayer } from "../../helpers/heatmapLayer";
import {
  sortVendorsByDistance,
  vendorBranchData,
} from "../../helpers/branchVendor";
import { escapeString } from "../../helpers/escapeString";
import { downloadCsv } from "../../helpers/downloadCsv";
import { Header } from "../../components/Header";
import { ActionButtons } from "../../components/ActionButtons";
import { CardButton, TextInput } from "@fsg/gui-bits";
import {
  clusterLayer,
  countLayer,
  entityLayer,
  unclusteredPointLayer,
} from "../../helpers/clusterLayer";
import { FixedSizeList as List } from "react-window";

const uspsData = usps as Array<any>;

function USPS({ publicView = false }) {
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [customerData] = useState<any>(uspsData);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const mapRef = useRef<MapRef>(null);
  const [filteredData, setFilteredData] = useState<any>(customerData);

  useEffect(() => {
    setFilteredData(
      customerData.filter((x: any) => {
        if (searchTerm === "") {
          return true;
        }
        return (
          x["name"].toLowerCase().includes(searchTerm.toLowerCase()) ||
          x["addr"].toLowerCase().includes(searchTerm.toLowerCase()) ||
          x["city"].toLowerCase().includes(searchTerm.toLowerCase()) ||
          x["st"].toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
    );
  }, [searchTerm, customerData]);

  document.title = `FSG ${publicView ? "(Public)" : ""} -  ${
    customerinfo.usps.name
  } Coverage Map`;

  const exportData = useCallback(() => {
    let csvContent =
      "City,State,Zip,V1,V1 Distance,V2,V2 Distance,V3,V3 Distance,V4,V4 Distance,V5,V5 Distance\n";
    customerData.forEach(function (row: any) {
      const vendors = sortVendorsByDistance(row["lat"], row["lon"]);
      const rowArray = [
        row["city"],
        row["st"],
        row["zip"],
        vendors[0]["Name"],
        vendors[0]["distance"].toFixed(1),
        vendors[1]["Name"],
        vendors[1]["distance"].toFixed(1),
        vendors[2]["Name"],
        vendors[2]["distance"].toFixed(1),
        vendors[3]["Name"],
        vendors[3]["distance"].toFixed(1),
        vendors[4]["Name"],
        vendors[4]["distance"].toFixed(1),
      ];
      csvContent += rowArray.map(escapeString).join(",");
      csvContent += "\n";
    });

    downloadCsv(csvContent, "usps");
  }, [customerData]);

  return (
    <div>
      <Header customer={customerinfo.usps.name} />
      <div className="dashboard-content">
        <ActionButtons
          customerGuid={customerinfo.usps.guid}
          publicView={publicView}
          exportData={exportData}
        />

        <div className="map-container">
          <div className="site-list">
            <div className="site-search">
              <TextInput
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for a site"
              />
            </div>
            <div className="site-list-list">
              <List
                itemCount={filteredData.length}
                itemSize={100}
                height={window.innerHeight - 240}
                width={"100%"}
              >
                {({ index, style }) => {
                  const item = filteredData[index];
                  return (
                    <div style={style}>
                      <CardButton
                        key={item["Name"]}
                        className="site-listing"
                        onClick={() => {
                          mapRef.current?.flyTo({
                            center: [item["lon"], item["lat"]],
                            zoom: 10,
                            duration: 1000,
                          });
                        }}
                        style={{ padding: "1rem", marginRight: "0.5rem" }}
                      >
                        <h4>{item["name"]}</h4>
                        <address>
                          <div>{item["addr"]}</div>
                          <div>
                            {item["city"]}, {item["st"]} {item["zip"]}
                          </div>
                        </address>
                      </CardButton>
                    </div>
                  );
                }}
              </List>
            </div>
          </div>
          <Map
            ref={mapRef}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={{
              longitude: -95.7,
              latitude: 37.09,
              zoom: 3,
            }}
            style={{ width: "100%", height: "80vh", marginTop: "1rem" }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            interactiveLayerIds={[
              "cluster",
              "counts",
              "unclustered-points",
              "entity-types",
            ]}
            onClick={(event) => {
              if (mapRef.current) {
                const features = mapRef.current.queryRenderedFeatures(
                  event.point as any,
                  {
                    layers: ["cluster"],
                  }
                );
                const feature = features![0] as any;
                if (!feature) return;

                const clusterId = feature.properties.cluster_id;

                if (clusterId) {
                  const mapboxSource = mapRef.current.getSource("usps") as any;

                  mapboxSource.getClusterExpansionZoom(
                    clusterId,
                    (err: any, zoom: any) => {
                      if (err || !feature) {
                        return;
                      }

                      if (!feature.geometry || !feature.geometry.coordinates)
                        return;

                      mapRef.current!.easeTo({
                        center: feature.geometry.coordinates,
                        zoom,
                        duration: 500,
                      });
                    }
                  );
                }
              }
            }}
            onDrag={() => {
              setSelectedLocation(null);
            }}
          >
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />

            <Source
              type="geojson"
              data={{
                type: "FeatureCollection",
                features: vendorBranchData.map((item) => ({
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: [item["Longitude"], item["Latitude"]],
                  },
                })),
              }}
            >
              <Layer {...heatmapLayer}></Layer>
            </Source>

            <Source
              id="usps"
              type="geojson"
              data={{
                type: "FeatureCollection",
                features: customerData.map((item: any) => ({
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: [item["lon"], item["lat"]],
                  },
                })),
              }}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={50}
            >
              <Layer {...clusterLayer(["#336", "#e71921"])}></Layer>
              <Layer {...countLayer}></Layer>
              <Layer {...entityLayer}></Layer>
              <Layer {...unclusteredPointLayer}></Layer>
            </Source>

            {/* {customerData.map((item : any) =>
              <Marker
                key={item["External Property ID"]}
                lon={item["lon"]}
                lat={item["lat"]}
                onClick={(e) => {
                  e.originalEvent.preventDefault();
                  e.originalEvent.stopPropagation();
                  if (selectedLocation) {
                    setSelectedLocation(null);
                  } else {
                    setSelectedLocation(item);
                  }
                }}
                anchor="bottom"
              >
                <Pin fill="#0c0d29" />
              </Marker>
            )} */}
            {selectedLocation && (
              <Popup
                anchor="top"
                longitude={selectedLocation["lon"]}
                latitude={selectedLocation["lat"]}
                onClose={() => {
                  setSelectedLocation(null);
                }}
                style={{ width: "50vw", maxWidth: "500px" }}
              >
                <div>
                  <div className="selected-property">
                    <h1>{selectedLocation["Property Name"]}</h1>
                    <h2>
                      {selectedLocation["city"]}, {selectedLocation["st"]}{" "}
                      {selectedLocation["zip"]}
                    </h2>
                  </div>
                  {!publicView && (
                    <div>
                      {sortVendorsByDistance(
                        selectedLocation["lat"],
                        selectedLocation["lon"]
                      ).map((item, i) => (
                        <div key={`vendor-${i}`} className="vendor-listing">
                          <div className="heading">
                            <h3>{item["Name"]}</h3>
                            <div>{item.distance.toFixed(1)} miles</div>
                          </div>
                          <address>
                            <div>
                              {item["Address"]}, {item["City"]}, {item["State"]}{" "}
                              {item["Zip Code"]}
                            </div>
                          </address>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Popup>
            )}
          </Map>
        </div>
      </div>
    </div>
  );
}

export default USPS;
