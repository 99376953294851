import { useState, useCallback, useRef } from 'react';
import './Starbucks.scss';
import starbucks from '../../data/starbucks.json';
import customerinfo from "../../data/customerinfo";
//import vendorPricing from '../../data/vendor_pricing.json';
import Map, {
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Layer,
  Source,
  MapRef,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { heatmapLayer } from '../../helpers/heatmapLayer';
import { sortVendorsByDistance, vendorBranchData } from '../../helpers/branchVendor';
import { escapeString } from '../../helpers/escapeString';
import { downloadCsv } from '../../helpers/downloadCsv';
import { Header } from '../../components/Header';
import { ActionButtons } from '../../components/ActionButtons';
import { CardButton, TextInput } from '@fsg/gui-bits';
import {
  clusterLayer,
  countLayer,
  entityLayer,
  unclusteredPointLayer,
} from "../../helpers/clusterLayer";

const starbucksData = (starbucks as Array<any>).filter((item : any) => item["lt"] && item["lg"]);


function Starbucks({ publicView = false}) {
  const [customerData, ] = useState<any>(starbucksData);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const mapRef = useRef<MapRef>(null);

  document.title = `FSG ${publicView ? "(Public)" : ""} -  ${customerinfo.starbucks.name} Coverage Map`;

  const exportData = useCallback(() => {

    let csvContent = "City,State,Zip,V1,V1 Distance,V2,V2 Distance,V3,V3 Distance,V4,V4 Distance,V5,V5 Distance\n"
    customerData.forEach(function (row : any) {
      const vendors = sortVendorsByDistance(row["lt"], row["lg"]);
      const rowArray = [
        row["City"],
        row["State"],
        row["zip"],
        vendors[0]["Name"],
        vendors[0]["distance"].toFixed(1),
        vendors[1]["Name"],
        vendors[1]["distance"].toFixed(1),
        vendors[2]["Name"],
        vendors[2]["distance"].toFixed(1),
        vendors[3]["Name"],
        vendors[3]["distance"].toFixed(1),
        vendors[4]["Name"],
        vendors[4]["distance"].toFixed(1),
      ]
      csvContent += rowArray.map(escapeString).join(",");
      csvContent += "\n";
    });

    downloadCsv(csvContent, "starbucks");
  }, [customerData]);

  return (
    <div>
      <Header customer={customerinfo.starbucks.name} />
      <div className="dashboard-content">

        <ActionButtons customerGuid={customerinfo.starbucks.guid} publicView={publicView} exportData={exportData} />

        <div className="map-container">
        <div className="site-list">
            <div className="site-search">
              <TextInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search for a site" />
            </div>
            <div className="site-list-list">
              {customerData.filter((x : any) => {
                if (searchTerm === "") {
                  return true;
                }
                return x["Site"].toLowerCase().includes(searchTerm.toLowerCase()) || 
                  x["Add1"].toLowerCase().includes(searchTerm.toLowerCase()) ||
                  x["City"].toLowerCase().includes(searchTerm.toLowerCase()) ||
                  x["State"].toLowerCase().includes(searchTerm.toLowerCase()) ||
                  x["zip"].toLowerCase().includes(searchTerm.toLowerCase())

              }).map((item : any) => {
                return (
                  <CardButton key={item["Name"]} className="site-listing" onClick={() => {
                    mapRef.current?.flyTo({
                      center: [item["lg"], item["lt"]],
                      zoom: 10,
                      duration: 1000
                    })
                  }}>
                    <h4>{item["Site"]}</h4>
                    <address>
                      <div>{item["Add1"]}</div><div>{item["City"]}, {item["State"]} {item["zip"]}</div>
                    </address>
                  </CardButton>
                )
              })}
            </div>
          </div>
          <Map
            ref={mapRef}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={{
              longitude: -95.7,
              latitude: 37.09,
              zoom: 3
            }}
            style={{ width: "100%", height: "80vh", marginTop: "1rem" }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            onClick={(event) => {
              if (mapRef.current) {
                const features = mapRef.current.queryRenderedFeatures(
                  event.point as any,
                  {
                    layers: ["cluster"],
                  }
                );
                const feature = features![0] as any;
                if (!feature) return;

                const clusterId = feature.properties.cluster_id;

                if (clusterId) {
                  const mapboxSource = mapRef.current.getSource("usps") as any;

                  mapboxSource.getClusterExpansionZoom(
                    clusterId,
                    (err: any, zoom: any) => {
                      if (err || !feature) {
                        return;
                      }

                      if (!feature.geometry || !feature.geometry.coordinates)
                        return;

                      mapRef.current!.easeTo({
                        center: feature.geometry.coordinates,
                        zoom,
                        duration: 500,
                      });
                    }
                  );
                }
              }
            }}
          >
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />

            <Source type="geojson" data={{
                "type": "FeatureCollection",
                "features" : vendorBranchData.map((item) => ({
                  "type": "Feature",
                  "properties": {
                  },
                  "geometry": {
                    "type": "Point",
                    "coordinates": [item["Longitude"], item["Latitude"]]
                  }
                }))
              }}>
              <Layer {...heatmapLayer} ></Layer>
            </Source>

            <Source
              id="usps"
              type="geojson"
              data={{
                type: "FeatureCollection",
                features: customerData.map((item: any) => ({
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: [item["lg"], item["lt"]],
                  },
                })),
              }}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={50}
            >
              <Layer {...clusterLayer(["#036635", "#036635"])}></Layer>
              <Layer {...countLayer}></Layer>
              <Layer {...entityLayer}></Layer>
              <Layer {...unclusteredPointLayer}></Layer>
            </Source>
          </Map>
        </div>
      </div>
    </div>
  );
}

export default Starbucks;
