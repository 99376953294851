import { useState, useCallback, useRef } from 'react';
import { Card, CardButton, CheckboxInput, TextInput } from '@fsg/gui-bits';
import './TA.scss';
import ta from '../../data/ta.json';
import customerinfo from "../../data/customerinfo";
//import vendorPricing from '../../data/vendor_pricing.json';
import Map, { Marker, Popup, 
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer,
  MapRef,
} from "react-map-gl";
import Pin from "../../assets/pin";
import "mapbox-gl/dist/mapbox-gl.css";
import { heatmapLayer } from '../../helpers/heatmapLayer';
import { sortVendorsByDistance, vendorBranchData } from '../../helpers/branchVendor';
import { escapeString } from '../../helpers/escapeString';
import { downloadCsv } from '../../helpers/downloadCsv';
import { Header } from '../../components/Header';
import { ActionButtons } from '../../components/ActionButtons';

const taData = ta.filter((item) => item["Latitude"] && item["Longitude"]);

function TAPage({ publicView = false}) {
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [customerData, ] = useState<any>(taData);
  const [showOnlyActive, setShowOnlyActive] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const map = useRef<MapRef>(null);

  document.title = `FSG ${publicView ? "(Public)" : ""} -  ${customerinfo.ta.name} Coverage Map`;

  const exportData = useCallback(() => {
    let csvContent = "Site ID,Name,Address,City,State,Zip,V1,V1 Distance,V2,V2 Distance,V3,V3 Distance,V4,V4 Distance,V5,V5 Distance\n"
    customerData.forEach(function (row : any) {
      const vendors = sortVendorsByDistance(row["Latitude"], row["Longitude"]);
      const rowArray = [
        row["External Property ID"],
        row["Property Name"],
        row["Address 1"],
        row["City"],
        row["State"],
        row["Zip"],
        vendors[0]["Name"],
        vendors[0]["distance"].toFixed(1),
        vendors[1]["Name"],
        vendors[1]["distance"].toFixed(1),
        vendors[2]["Name"],
        vendors[2]["distance"].toFixed(1),
        vendors[3]["Name"],
        vendors[3]["distance"].toFixed(1),
        vendors[4]["Name"],
        vendors[4]["distance"].toFixed(1),
      ]
      csvContent += rowArray.map(escapeString).join(",");
      csvContent += "\n";
    });

    downloadCsv(csvContent, "ta");
  }, [customerData]);

  return (
    <div>
      <Header customer={customerinfo.ta.name} />
      <div className="dashboard-content">
        
        <ActionButtons customerGuid={customerinfo.ta.guid} publicView={publicView} exportData={exportData} />

        <div style={{ marginTop: "1rem"}}>
          <CheckboxInput label="Show only active sites" checked={showOnlyActive} onChange={(e) => setShowOnlyActive(e.target.checked)} />
        </div>

        <div className="map-container">
          <div className="site-list">
            <div className="site-search">
              <TextInput value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search for a site" />
            </div>
            <div className="site-list-list">
              {customerData.filter((x:any) => {
                if (showOnlyActive && x["Awarded"] === "No") return false;
                if (searchTerm === "") return true;

                return x["Name"].toLowerCase().includes(searchTerm.toLowerCase()) ||
                  x["Address1"].toLowerCase().includes(searchTerm.toLowerCase());
              }).map((item : any) => {
                return (
                  <CardButton key={item["Site"]} className="site-listing" onClick={() => {
                    map.current?.flyTo({
                      center: [item["Longitude"], item["Latitude"]],
                      zoom: 10,
                      duration: 1000
                    })
                  }}>
                    <h4>{item["Name"]}</h4>
                    <address>
                      <div>{item["Address1"]}</div><div>{item["City"]}, {item["State"]} {item["Zip"]}</div>
                    </address>
                  </CardButton>
                )
              })}
            </div>
          </div>
          <Map
            ref={map}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={{
              longitude: -95.7,
              latitude: 37.09,
              zoom: 3
            }}
            style={{ width: "100%", height: "80vh", marginTop: "1rem" }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            onClick={() => { setSelectedLocation(null) }}
          >
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />

            <Source type="geojson" data={{
              "type": "FeatureCollection",
              "features" : vendorBranchData.map((item) => ({
                "type": "Feature",
                "properties": {
                },
                "geometry": {
                  "type": "Point",
                  "coordinates": [item["Longitude"], item["Latitude"]]
                }
              }))
            }}>
              <Layer {...heatmapLayer} ></Layer>
            </Source>

            {!showOnlyActive && customerData.filter((x: any) => x["Awarded"] === "No").map((item : any) =>
              <Marker
                key={item["External Property ID"]}
                longitude={item["Longitude"]}
                latitude={item["Latitude"]}
                onClick={(e) => {
                  e.originalEvent.preventDefault();
                  e.originalEvent.stopPropagation();
                  if (selectedLocation) {
                    setSelectedLocation(null);
                  } else {
                    setSelectedLocation(item);
                  }
                }}
                anchor="bottom"
              >
                <Pin fill="rgba(237, 28, 46, 0.5)" />
              </Marker>
            )}

            {customerData.filter((x: any) => x["Awarded"] === "Yes").map((item : any) =>
              <Marker
                key={item["External Property ID"]}
                longitude={item["Longitude"]}
                latitude={item["Latitude"]}
                onClick={(e) => {
                  e.originalEvent.preventDefault();
                  e.originalEvent.stopPropagation();
                  if (selectedLocation) {
                    setSelectedLocation(null);
                  } else {
                    setSelectedLocation(item);
                  }
                }}
                anchor="bottom"
              >
                  <Pin fill="#0060a9" />

              </Marker>
            )}

                
            {selectedLocation && (
              <Popup
                anchor='top'
                longitude={selectedLocation["Longitude"]}
                latitude={selectedLocation["Latitude"]}
                onClose={() => {
                  setSelectedLocation(null);
                }}
                style={{ width: "50vw !important", maxWidth: "100vw !important"}}
              >
                <div>
                  <div className="selected-property">
                    <h2>{selectedLocation["Name"]}</h2>
                    <div>
                      {selectedLocation["Address1"]}, {selectedLocation["City"]}, {selectedLocation["State"]} {selectedLocation["Zip"]}
                    </div>
                  </div>
                  <h3>FSG Contact Info</h3>
                  <div className="contact-info">
                    <Card>
                      <h4>TEAM 4 - Service Coordinator: Taylor Worden<br />
                      Backup: Mauricea Fletcher </h4>
                      <a href="mailto:team4@fsgi.com" target="_blank" rel="noreferrer" >team4@fsgi.com</a><br />
                      Phone: <a href="tel:18003475530">1-800-347-5530</a>
                    </Card>                  
                    <Card>
                      <h4>CO - Lead</h4>
                      <a href="mailto:Javier.Mejia@fsgi.com" target="_blank" rel="noreferrer" >Javier.Mejia@fsgi.com</a><br />
                      Phone: <a href="tel:469-684-0043">469-684-0043</a>
                    </Card>
                    <Card>
                      <h4>Team Lead</h4>
                      <a href="mailto:Aaron.Schrader@fsgi.com" target="_blank" rel="noreferrer">Aaron.Schrader@fsgi.com</a><br />
                      Phone: <a href="tel:214-317-2270">214-317-2270</a>
                    </Card>
                    <Card>
                      <h4>Service Manager</h4>
                      <a href="mailto:steven.m.smith@fsgi.com" target="_blank" rel="noreferrer">Steven.M.Smith@fsgi.com</a><br />
                      Phone: <a href="tel:800-347-5530">800-347-5530 Ext. 10369</a><br />
                      Cell: <a href="tel:817-372-2308">817-372-2308</a>
                    </Card>
                    <Card>
                      <h4>National Account Manager</h4>
                      <a href="mailto:Kelly.Cole@fsgi.com" target="_blank" rel="noreferrer">Kelly.Cole@fsgi.com</a><br />
                      Phone: <a href="tel:212-776-7930">212-776-7930 ext 21244</a><br />
                      Cell: <a href="tel:929-246-9319">929-246-9319</a>
                    </Card>
                    <Card>
                      <h4>Director of Projects</h4>
                      <a href="mailto:chris.wills@fsgi.com" target="_blank" rel="noreferrer">Chris.Wills@fsgi.com</a><br />
                      Phone: <a href="tel:800-347-5530">800-347-5530 Ext. 10895</a><br />
                      Cell: <a href="tel:214-876-5024">214-876-5024</a>
                    </Card>
                    <Card>
                      <h4>Financial Manager</h4>
                      <a href="mailto:sherri.wendler@fsgi.com" target="_blank" rel="noreferrer">Sherri.Wendler@fsgi.com</a><br />
                      Phone: <a href="tel:800-347-5530">800-347-5530 Ext. 10249</a><br />
                      Cell: <a href="tel:817-944-3699">817-944-3699</a>
                    </Card>
                  </div>
                  {!publicView && 
                    <div>
                      {sortVendorsByDistance(selectedLocation["Latitude"], selectedLocation["Longitude"]).map((item, i) => 
                        <div key={`vendor-${i}`} className="vendor-listing">
                          <div className="heading">
                            <h3>{item["Name"]}</h3>
                            <div>{item.distance.toFixed(1)} miles</div>
                          </div>
                          <address>
                            <div>{item["Address"]}, {item["City"]}, {item["State"]} {item["Zip Code"]}</div>
                          </address>
                        </div>
                      )}
                    </div>
                  }
                </div>
              </Popup>
            )}
          </Map>
        </div>
      </div>
    </div>
  );
}

export default TAPage;

