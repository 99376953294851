import { useState, useCallback, useRef } from "react";
import "./711.scss";
import __711 from "../../data/711.json";
import customerinfo from "../../data/customerinfo";
import Map, {
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Layer,
  Source,
  MapRef,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { heatmapLayer } from "../../helpers/heatmapLayer";
import {
  sortVendorsByDistance,
  vendorBranchData,
} from "../../helpers/branchVendor";
import { escapeString } from "../../helpers/escapeString";
import { downloadCsv } from "../../helpers/downloadCsv";
import { Header } from "../../components/Header";
import { ActionButtons } from "../../components/ActionButtons";
import { CardButton, TextInput } from "@fsg/gui-bits";
import {
  clusterLayer,
  countLayer,
  entityLayer,
  unclusteredPointLayer,
} from "../../helpers/clusterLayer";

const _711Data = (__711 as Array<any>).filter(
  (item: any) => item["Latitude"] && item["Longitude"]
);

function _711({ publicView = false }) {
  const [customerData] = useState<any>(_711Data);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const mapRef = useRef<MapRef>(null);

  document.title = `FSG ${publicView ? "(Public)" : ""} -  ${
    customerinfo["711"].name
  } Coverage Map`;

  const exportData = useCallback(() => {
    let csvContent =
      "City,State,Zip,V1,V1 Distance,V2,V2 Distance,V3,V3 Distance,V4,V4 Distance,V5,V5 Distance\n";
    customerData.forEach(function (row: any) {
      const vendors = sortVendorsByDistance(row["Latitude"], row["Longitude"]);
      const rowArray = [
        row["City"],
        row["State"],
        row["Zip"],
        vendors[0]["Name"],
        vendors[0]["distance"].toFixed(1),
        vendors[1]["Name"],
        vendors[1]["distance"].toFixed(1),
        vendors[2]["Name"],
        vendors[2]["distance"].toFixed(1),
        vendors[3]["Name"],
        vendors[3]["distance"].toFixed(1),
        vendors[4]["Name"],
        vendors[4]["distance"].toFixed(1),
      ];
      csvContent += rowArray.map(escapeString).join(",");
      csvContent += "\n";
    });

    downloadCsv(csvContent, "711");
  }, [customerData]);

  return (
    <div>
      <Header customer={customerinfo["711"].name} />
      <div className="dashboard-content">
        <ActionButtons
          customerGuid={customerinfo["711"].guid}
          publicView={publicView}
          exportData={exportData}
        />

        <div className="map-container">
          <div className="site-list">
            <div className="site-search">
              <TextInput
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for a site"
              />
            </div>
            <div className="site-list-list">
              {customerData
                .filter((x: any) => {
                  if (searchTerm === "") {
                    return true;
                  }
                  return (
                    x["Loc_Nbr"]
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    x["Address"]
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    x["City"]
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    x["Zip"].toLowerCase().includes(searchTerm.toLowerCase()) ||
                    x["State"].toLowerCase().includes(searchTerm.toLowerCase())
                  );
                })
                .map((item: any) => {
                  return (
                    <CardButton
                      key={item["Name"]}
                      className="site-listing"
                      onClick={() => {
                        mapRef.current?.flyTo({
                          center: [item["Longitude"], item["Latitude"]],
                          zoom: 10,
                          duration: 1000,
                        });
                      }}
                    >
                      <h4>Location {item["Loc_Nbr"]}</h4>
                      <address>
                        <div>{item["Address"]}</div>
                        <div>
                          {item["City"]}, {item["State"]} {item["Zip"]}
                        </div>
                      </address>
                    </CardButton>
                  );
                })}
            </div>
          </div>
          <Map
            ref={mapRef}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={{
              longitude: -95.7,
              latitude: 37.09,
              zoom: 3,
            }}
            style={{ width: "100%", height: "80vh", marginTop: "1rem" }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            interactiveLayerIds={[
              "cluster",
              "counts",
              "unclustered-points",
              "entity-types",
            ]}
            onClick={(event) => {
              if (mapRef.current) {
                const features = mapRef.current.queryRenderedFeatures(
                  event.point as any,
                  {
                    layers: ["cluster"],
                  }
                );
                const feature = features![0] as any;
                if (!feature) return;

                const clusterId = feature.properties.cluster_id;

                if (clusterId) {
                  const mapboxSource = mapRef.current.getSource("711") as any;

                  mapboxSource.getClusterExpansionZoom(
                    clusterId,
                    (err: any, zoom: any) => {
                      if (err || !feature) {
                        return;
                      }

                      if (!feature.geometry || !feature.geometry.coordinates)
                        return;

                      mapRef.current!.easeTo({
                        center: feature.geometry.coordinates,
                        zoom,
                        duration: 500,
                      });
                    }
                  );
                }
              }
            }}
          >
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />

            <Source
              type="geojson"
              data={{
                type: "FeatureCollection",
                features: vendorBranchData.map((item) => ({
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: [item["Longitude"], item["Latitude"]],
                  },
                })),
              }}
            >
              <Layer {...heatmapLayer}></Layer>
            </Source>

            <Source
              id="711"
              type="geojson"
              data={{
                type: "FeatureCollection",
                features: customerData.map((item: any) => ({
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: [item["Longitude"], item["Latitude"]],
                  },
                })),
              }}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={50}
            >
              <Layer
                {...clusterLayer(["#F4811F", "#EE2526", "#008163"])}
              ></Layer>
              <Layer {...countLayer}></Layer>
              <Layer {...entityLayer}></Layer>
              <Layer {...unclusteredPointLayer}></Layer>
            </Source>
          </Map>
        </div>
      </div>
    </div>
  );
}

export default _711;
