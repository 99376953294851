import { CircleLayer, LayerProps } from "react-map-gl";

export const clusterLayer = (colors: string[]) => {
  return {
  id : 'cluster',
  type : 'circle',
  paint: {
    'circle-color': [
      'step',
      ['get', 'point_count'],
      colors[0],
      100,
      colors[1],
      750,
      colors.length > 2 ? colors[2] : colors[0]
    ],
    'circle-radius': [
      'step',
      ['get', 'point_count'],
      20,
      100,
      30,
      750,
      40
    ]
  }
} as CircleLayer};

export const countLayer: LayerProps = {
  id: 'counts',
  type: 'symbol',
  source: 'points',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': ['get', 'point_count_abbreviated'],
    'text-font': ['Arial Unicode MS Bold'],
  },
  paint: {
    'text-color': 'rgb(255, 255, 255)',
  }
};

export const entityLayer: LayerProps = {
  id: 'entity-types',
  type: 'symbol',
  source: 'points',
  filter: ['has', 'type'],
  layout: {
    'text-field': ['get', 'type'],
    'text-font': ['Arial Unicode MS Bold'],
  },
  paint: {
    'text-color': 'rgb(45, 83, 209)',
  }
};

export const unclusteredPointLayer: LayerProps = {
  id: 'unclustered-points',
  type: 'circle',
  source: 'points',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#dae5fd',
    'circle-radius': 10,
    'circle-stroke-width': 1,
    'circle-stroke-color': 'rgb(45, 83, 209)'
  }
};
