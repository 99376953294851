import { LoadingSpinner } from "@fsg/gui-bits"
import "./LoadingPage.scss"

const LoadingPage = () => {
  return (
    <div id="app-loading-page">
      <LoadingSpinner />
    </div>
  )
}
export default LoadingPage
