import { useState, useCallback, useRef } from "react";
import "./DukeMountain.scss";
import duke from "../../data/duke_mountain.json";
import customerinfo from "../../data/customerinfo";
//import vendorPricing from '../../data/vendor_pricing.json';
import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Layer,
  Source,
  MapRef,
} from "react-map-gl";
import Pin from "../../assets/pin";
import "mapbox-gl/dist/mapbox-gl.css";
import { heatmapLayer } from "../../helpers/heatmapLayer";
import {
  sortVendorsByDistance,
  vendorBranchData,
} from "../../helpers/branchVendor";
import { escapeString } from "../../helpers/escapeString";
import { downloadCsv } from "../../helpers/downloadCsv";
import { Header } from "../../components/Header";
import { ActionButtons } from "../../components/ActionButtons";
import { CardButton, TextInput } from "@fsg/gui-bits";

const dukeData = duke.filter(
  (item: any) => item["Latitude"] && item["Longitude"]
);

function DukeMountain({ publicView = false }) {
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [customerData] = useState<any>(dukeData);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const map = useRef<MapRef>(null);

  document.title = `FSG ${publicView ? "(Public)" : ""} -  ${
    customerinfo["duke-mountain"].name
  } Coverage Map`;

  const exportData = useCallback(() => {
    let csvContent =
      "Sub-Region,Name,Number,ID,City,State,Zip,V1,V1 Distance,V2,V2 Distance,V3,V3 Distance,V4,V4 Distance,V5,V5 Distance\n";
    customerData.forEach(function (row: any) {
      const vendors = sortVendorsByDistance(row["Latitude"], row["Longitude"]);
      const rowArray = [
        row["Sub-Region"],
        row["Name"],
        row["Number"],
        row["ID"],
        row["City"],
        row["State"],
        row["Zip"],
        vendors[0]["Name"],
        vendors[0]["distance"].toFixed(1),
        vendors[1]["Name"],
        vendors[1]["distance"].toFixed(1),
        vendors[2]["Name"],
        vendors[2]["distance"].toFixed(1),
        vendors[3]["Name"],
        vendors[3]["distance"].toFixed(1),
        vendors[4]["Name"],
        vendors[4]["distance"].toFixed(1),
      ];
      csvContent += rowArray.map(escapeString).join(",");
      csvContent += "\n";
    });

    downloadCsv(csvContent, "duke");
  }, [customerData]);

  return (
    <div>
      <Header customer={customerinfo["duke-mountain"].name} />
      <div className="dashboard-content">
        <ActionButtons
          customerGuid={customerinfo["duke-mountain"].guid}
          publicView={publicView}
          exportData={exportData}
        />

        <div className="map-container">
          <div className="site-list">
            <div className="site-search">
              <TextInput
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for a site"
              />
            </div>
            <div className="site-list-list">
              {customerData
                .filter((x: any) => {
                  if (searchTerm === "") {
                    return true;
                  }
                  return (
                    x["Name"]
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    x["Number"]
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    x["Address"]
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    x["City"]
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    x["State"]
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    x["Zip"].toLowerCase().includes(searchTerm.toLowerCase())
                  );
                })
                .map((item: any) => {
                  return (
                    <CardButton
                      key={item["Name"]}
                      className="site-listing"
                      onClick={() => {
                        map.current?.flyTo({
                          center: [item["Longitude"], item["Latitude"]],
                          zoom: 10,
                          duration: 1000,
                        });
                      }}
                    >
                      <h4>
                        {item["Name"]} ({item["Number"]})
                      </h4>
                      <address>
                        <div>{item["Address"]}</div>
                        <div>
                          {item["City"]}, {item["State"]} {item["Zip"]}
                        </div>
                      </address>
                    </CardButton>
                  );
                })}
            </div>
          </div>
          <Map
            ref={map}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={{
              longitude: -95.7,
              latitude: 37.09,
              zoom: 3,
            }}
            style={{ width: "100%", height: "80vh", marginTop: "1rem" }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            onClick={() => {
              setSelectedLocation(null);
            }}
            onDrag={() => {
              setSelectedLocation(null);
            }}
          >
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />

            <Source
              type="geojson"
              data={{
                type: "FeatureCollection",
                features: vendorBranchData.map((item) => ({
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: [item["Longitude"], item["Latitude"]],
                  },
                })),
              }}
            >
              <Layer {...heatmapLayer}></Layer>
            </Source>

            {customerData.map((item: any) => (
              <Marker
                key={item["Number"]}
                longitude={item["Longitude"]}
                latitude={item["Latitude"]}
                onClick={(e) => {
                  e.originalEvent.preventDefault();
                  e.originalEvent.stopPropagation();
                  if (selectedLocation) {
                    setSelectedLocation(null);
                  } else {
                    setSelectedLocation(item);
                  }
                }}
                anchor="bottom"
              >
                <Pin fill="#FF671F" />
              </Marker>
            ))}
            {selectedLocation && (
              <Popup
                anchor="top"
                longitude={selectedLocation["Longitude"]}
                latitude={selectedLocation["Latitude"]}
                onClose={() => {
                  setSelectedLocation(null);
                }}
                style={{ width: "500px", maxWidth: "500px" }}
              >
                <div>
                  <div className="selected-property">
                    <div>
                      <h1>
                        {selectedLocation["Name"]} ({selectedLocation["Number"]}
                        )
                      </h1>
                      <h2>{selectedLocation["Sub-Region"]}</h2>
                    </div>

                    <address>
                      <div>{selectedLocation["Address"]}</div>
                      <div>
                        {selectedLocation["City"]}, {selectedLocation["State"]}{" "}
                        {selectedLocation["Zip"]}
                      </div>
                    </address>
                  </div>
                  {!publicView && (
                    <div>
                      {sortVendorsByDistance(
                        selectedLocation["Latitude"],
                        selectedLocation["Longitude"]
                      ).map((item, i) => (
                        <div key={`vendor-${i}`} className="vendor-listing">
                          <div className="heading">
                            <h3>{item["Name"]}</h3>
                            <div>{item.distance.toFixed(1)} miles</div>
                          </div>
                          <address>
                            <div>
                              {item["Address"]}, {item["City"]}, {item["State"]}{" "}
                              {item["Zip Code"]}
                            </div>
                          </address>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Popup>
            )}
          </Map>
        </div>
      </div>
    </div>
  );
}

export default DukeMountain;
