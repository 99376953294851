import React, { useContext, useEffect, useState } from "react"
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom"
import { NavItem, NavLabel, UserMenu, Button } from "@fsg/gui-bits"
import { ReactComponent as Logo } from "../assets/logo.svg"
import { ReactComponent as Menu } from "../assets/menu.svg"
import { ReactComponent as Map } from '../assets/map.svg';
import "./App.scss"
import { apiClient } from "../api"
import { AuthContext } from "@fsg/apps-api-client-react"
import LoadingPage from "../pages/Auth/Loading/LoadingPage"
import Login from "../pages/Auth/LoginPage"
import Public from "./Public"
import customerInfo from "../data/customerinfo"

const AppAuthWrapper = () => {
  const { loading: authIsLoading, isAuthenticated } = useContext(AuthContext)

  if (window.location.pathname.startsWith("/public-view")) return <Public />

  if (authIsLoading) return <LoadingPage />

  if (!isAuthenticated) return <Login />

  return <App />
}

function App() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  useEffect(() => {
    setMobileNavOpen(false)
  }, [location, navigate])

  const UserMenuComponent = () => {
    return (
      <UserMenu
        className="user"
        name={user?.name}
        actions={[
          {
            label: `Logout`,
            onClick: () => {
              window.location.href = apiClient.logoutUri(window.location.origin)
            },
          },
        ]}
      />
    )
  }
  const UserMemo = React.memo(UserMenuComponent)

  return (
    <div className="app-wrapper">
      <div className={`navigation ${mobileNavOpen ? `mobile-open` : ``}`}>
        <NavLink className="logo" to="/">
          <Logo />
        </NavLink>
        <div className="navigation-links">

          <NavLabel icon={ Map } className="nav-label">
            Customers
          </NavLabel>
          {Object.keys(customerInfo).map((key) => {
            return (
              <NavItem key={key} className="nav-item" to={`/${key}`}>
                {customerInfo[key].name}
              </NavItem>
            )
          })}
        </div>
        <div className="spacer" />
        <UserMemo />
      </div>
      <main>
        <Outlet />

        <footer className="mobile-navigation">
          <NavLink className="logo" to="/">
            <Logo />
          </NavLink>
          <Button
            withIcon
            buttonType="secondary"
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
          >
            <Menu />
          </Button>
        </footer>
      </main>
    </div>
  )
}

export default AppAuthWrapper;
